import React from "react"
import styled from "styled-components"

import { h2 } from "base_css/mixins"
import { rems } from "base_css/utils/fontSize"
import GetStartedButton from "components/molecules/GetStartedButton"

const SGetStartedSection = styled.div<IGetStartedSection>`
  background-color: ${({ isDark, theme }) =>
    isDark ? theme.color.primary : theme.color.accent};
  margin-top: auto;
  padding-bottom: ${rems(125)};
  padding-top: ${rems(125)};
  text-align: center;
`

const SHeader = styled.h1`
  ${h2} /* stylelint-disable-next-line */
  color: ${({ theme }) => theme.color.white};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  text-align: center;
`

interface IGetStartedSection {
  isDark: boolean
}

const GetStartedSection = ({ isDark }: IGetStartedSection) => (
  <SGetStartedSection isDark={isDark}>
    <SHeader>Ready to get started?</SHeader>
    <GetStartedButton
      variant={isDark ? "secondary" : "pinkSecondary"}
      analyticsID="GetStartedSection GetStartedButton"
    />
  </SGetStartedSection>
)

export default GetStartedSection
