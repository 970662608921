import React from "react"
// keep order imports as auto sorting breaks build
import App, { AppProps, AppContext } from "next/app"
// keep order imports as auto sorting breaks build

import styled, { ThemeProvider } from "styled-components"
import {
  QueryClient,
  QueryClientProvider,
  Hydrate,
  dehydrate,
} from "react-query"
import { Provider } from "react-redux"

import CompanyContextProvider from "companyContext"
import helpScout from "features/help-scout"
import { store } from "features/store/store"
import theme from "themes/default"
import Normalize from "base_css/normalize"
import GlobalStyle from "base_css/global"
import FlashMessage from "components/atoms/FlashMessage"
import "base_css/index.css"

const SApp = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`

function MyApp({ Component, pageProps }: AppProps) {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const _Component = Component as any
  const [queryClient] = React.useState(() => new QueryClient())

  React.useEffect(() => {
    helpScout.init()
  }, [])

  return (
    <SApp>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <CompanyContextProvider>
              <ThemeProvider theme={theme}>
                <Normalize />
                <GlobalStyle />
                <FlashMessage />
                <_Component {...pageProps} />
              </ThemeProvider>
            </CompanyContextProvider>
          </Hydrate>
        </QueryClientProvider>
      </Provider>
    </SApp>
  )
}

// required to get values in query from server rewrite
MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)

  const queryClient = new QueryClient()
  return { ...appProps, dehydratedState: dehydrate(queryClient) }
}

export default MyApp
