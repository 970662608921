import React from "react"

import * as ga from "features/analytics/ga"

interface IClickTracker {
  children: React.ReactNode
  analyticsID?: string
}

const ClickTracker = ({ children, analyticsID }: IClickTracker) => {
  const handleClick = () => {
    if (analyticsID) ga.clickEvent(analyticsID)
  }

  return (
    <React.Fragment>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return child
        }

        return React.cloneElement(child, {
          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
          onClick: (event: any) => {
            handleClick()
            if (child && child.props && child.props.onClick) {
              child.props.onClick.call(this, event)
            }
          },
        })
      })}
    </React.Fragment>
  )
}

export default ClickTracker
