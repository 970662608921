import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import type { RootState } from "features/store/store"
import { useAppSelector } from "features/store/store"

interface INavColorState {
  value: string | undefined
}

const initialState: INavColorState = {
  value: undefined,
}

export const navColorSlice = createSlice({
  name: "navColor",
  initialState,
  reducers: {
    setNavColor: (state, action: PayloadAction<string | undefined>) => {
      state.value = action.payload
    },
  },
})

export const { setNavColor } = navColorSlice.actions

export const useSelectorNavColor = () =>
  useAppSelector((state: RootState) => state.navColor.value)

export default navColorSlice.reducer
