import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import type { RootState } from "features/store/store"
import { useAppSelector } from "features/store/store"

import { FlashMessageCategory } from "components/atoms/FlashMessage"

const DEFAULT_ERROR_MESSAGE = "Oops, something went wrong, please try again"

interface IFlashMessage {
  category: FlashMessageCategory
  shown: boolean
  message?: string
}

interface IAppStatus {
  flashMessage: IFlashMessage
}

const initialState: IAppStatus = {
  flashMessage: {
    category: "info",
    message: undefined,
    shown: false,
  },
}

interface IFlashMessageShowPayload {
  category: FlashMessageCategory
  message: string
}

export const appStatusSlice = createSlice({
  name: "appStatus",
  initialState,
  reducers: {
    showFlash: (state, action: PayloadAction<IFlashMessageShowPayload>) => {
      state.flashMessage = {
        ...action.payload,
        shown: true,
      }
    },
    hideFlash: (state) => {
      state.flashMessage = {
        ...initialState.flashMessage,
        category: state.flashMessage.category,
      }
    },
    showDefaultErrorFlash: (state) => {
      state.flashMessage = {
        category: "error",
        message: DEFAULT_ERROR_MESSAGE,
        shown: true,
      }
    },
  },
})

export const { showFlash, hideFlash, showDefaultErrorFlash } =
  appStatusSlice.actions

export const useSelectorFlash = () =>
  useAppSelector((state: RootState) => state.appStatus.flashMessage)

export default appStatusSlice.reducer
