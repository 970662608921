import React from "react"
import styled from "styled-components"

import { Grid } from "components/atoms/Grid"

interface ICarouselSlide {
  active: boolean
  children?: React.ReactNode
}

const SCarouselSlide = styled(Grid)<ICarouselSlide>`
  flex: 0 0 auto;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: all 0.5s ease;
  width: 100%;
`

const CarouselSlide = ({ active, children }: ICarouselSlide) => (
  <SCarouselSlide active={active} columns={10}>
    {children}
  </SCarouselSlide>
)

export default CarouselSlide
