import { IGetTrustpilotResponse } from "features/api/types/trustpilot"
import config from "config"

export const getTrustpilotRating = async (): Promise<
  IGetTrustpilotResponse["score"]
> => {
  return (
    await fetch(config.services.novaService + "/v1/marketing/trustpilot")
  ).json()
}
