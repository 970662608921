import React from "react"
import styled from "styled-components"

import { h4 } from "base_css/mixins"
import { breakpoint } from "base_css/vars"
import Quotes from "components/svg/Quotes"
import { InlineExternalLink } from "components/atoms/CustomLink"

const SQuoteContainer = styled.div`
  display: flex;
  position: relative;
`

const SAuthorContainer = styled.div`
  color: ${({ theme }) => theme.color.white};
  display: flex;
  justify-content: center;
  margin-left: ${({ theme }) => theme.fontSize.h4};
`
const SAuthor = styled.p`
  font-weight: bold;
`

const SAuthorLink = styled(InlineExternalLink)`
  color: inherit !important;
  font-weight: bold;
  padding-top: 0;
`

const SLocation = styled.span`
  span {
    padding: 0 ${({ theme }) => theme.spacing.xSmall};
  }
`

const SQuotes = styled(Quotes)`
  max-width: ${({ theme }) => theme.fontSize.h4};
  min-width: ${({ theme }) => theme.fontSize.h4};
  position: relative;
  top: -${({ theme }) => theme.spacing.medium};

  ${breakpoint.large} {
    width: 85%;
  }
`

const SText = styled.div`
  ${h4} /* stylelint-disable-next-line */
  color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: normal;
  text-align: center;
`

interface IQuote {
  children: React.ReactNode
  className?: string
  color?: string
  author?: string
  location?: string
  href?: string
}

const Quote = ({
  children,
  className,
  color,
  author,
  location,
  href,
}: IQuote) => (
  <div className={className}>
    <SQuoteContainer>
      <SQuotes fill={color} />
      <SText>{children}</SText>
    </SQuoteContainer>
    <SAuthorContainer>
      {author && href && (
        <SAuthorLink target="_blank" href={href}>
          {author}
        </SAuthorLink>
      )}
      {author && !href && <SAuthor>{author}</SAuthor>}
      {location && author && <SLocation>&nbsp;- {location}</SLocation>}
    </SAuthorContainer>
  </div>
)

export default Quote
