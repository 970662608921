import React from "react"
import styled from "styled-components"

import { useSelectorFlash } from "features/slices/appStatusSlice"

export type FlashMessageCategory = "info" | "error" | "success" | "warning"

interface IFlashMessageStyle {
  category: FlashMessageCategory
  shown: boolean
}

const SFlashMessage = styled.div<IFlashMessageStyle>`
  background-color: ${({ theme, category }) => {
    switch (category) {
      case "info":
        return theme.color.primary
      case "error":
        return theme.color.state.error
      case "success":
        return theme.color.state.success
      case "warning":
        return theme.color.state.warning
    }
  }};
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.fontSize.small};
  padding: ${({ theme }) => theme.spacing.small};
  position: fixed;
  top: ${({ shown }) => (shown ? "0" : "-100px")};
  transition: top 0.5s ease-in-out;
  width: 100vw;
  z-index: ${({ theme }) => theme.zIndex.skyscraper};
`

const FlashMessage = () => {
  const { category, message, shown } = useSelectorFlash()

  return (
    <SFlashMessage category={category} shown={shown}>
      {message}
    </SFlashMessage>
  )
}

export default FlashMessage
