import React from "react"
import styled from "styled-components"

interface ISpinner {
  className?: string
  variant?: "dark" | "light"
}

const SSpinner = styled.svg<ISpinner>`
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: spin;
  animation-timing-function: linear;
  fill: ${({ variant, theme }) =>
    variant === "dark" ? theme.color.primary : theme.color.white};
  height: ${({ theme }) => theme.fontSize.small};

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`

export const Spinner = ({ className, variant = "dark" }: ISpinner) => (
  <SSpinner
    className={className}
    variant={variant}
    xmlns="http://www.w3.org/2000/svg"
    data-creative-commons-credit="Author: Mohammad Iqbal"
    viewBox="0 0 32 32"
  >
    <path d="M17 5.79v3.75a1 1 0 01-2 0V5.79a1 1 0 112 0zm3.57 6.64a1 1 0 00.71-.29l2.64-2.65a1 1 0 10-1.41-1.41l-2.65 2.64a1 1 0 000 1.42 1 1 0 00.71.29zM26.21 15h-3.75a1 1 0 000 2h3.75a1 1 0 000-2zm-4.93 4.86a1 1 0 00-1.42 1.42l2.65 2.64a1 1 0 001.41 0 1 1 0 000-1.41zM16 21.46a1 1 0 00-1 1v3.75a1 1 0 002 0v-3.75a1 1 0 00-1-1zm-5.28-1.6l-2.64 2.65a1 1 0 000 1.41 1 1 0 001.41 0l2.65-2.64a1 1 0 00-1.42-1.42zM10.54 16a1 1 0 00-1-1H5.79a1 1 0 100 2h3.75a1 1 0 001-1zm-1-7.92a1 1 0 00-1.46 1.41l2.64 2.65a1 1 0 001.42 0 1 1 0 000-1.42z" />
  </SSpinner>
)

export default Spinner
