import React from "react"
import styled from "styled-components"

import { rems } from "base_css/utils/fontSize"

interface ITrustpilotStar {
  size?: number
  fill?: string
}

const STrustpilotStar = styled.svg<ITrustpilotStar>`
  fill: ${({ fill, theme }) => fill ?? theme.color.white};
  height: ${({ size }) => rems(size ?? 18)};
  width: ${({ size }) => rems(size ?? 18)};
`

const TrustpilotStar = ({ size, fill }: ITrustpilotStar) => (
  <STrustpilotStar
    viewBox="0 0 24 24"
    role="img"
    version="1.1"
    size={size}
    fill={fill}
  >
    <title>Trustpilot star</title>
    <path d="M12,17.964l5.214-1.321l2.179,6.714L12,17.964z M24,9.286h-9.179L12,0.643L9.179,9.286 H0l7.429,5.357l-2.821,8.643l7.429-5.357l4.571-3.286L24,9.286L24,9.286L24,9.286L24,9.286z" />
  </STrustpilotStar>
)

export default TrustpilotStar
