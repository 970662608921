import React from "react"
import styled from "styled-components"

import { rems } from "base_css/utils/fontSize"

interface IScrollArrow {
  className?: string
  onClick?: () => void
}

const SScrollArrow = styled.svg`
  height: ${rems(30)};
`

const ScrollArrow = ({ className, onClick }: IScrollArrow) => (
  <SScrollArrow className={className} onClick={onClick} viewBox="0 0 40 40">
    <defs>
      <rect id="path-1" x="0" y="0" width="40" height="40" />
      <rect id="path-3" x="0" y="0" width="40" height="40" rx="20" />
      <filter
        x="-37.5%"
        y="-37.5%"
        width="175.0%"
        height="175.0%"
        filterUnits="objectBoundingBox"
        id="filter-4"
      >
        <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.05 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
    </defs>
    <g
      id="Page-designs"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Desktop---Resources---Landing-page"
        transform="translate(-89.000000, -325.000000)"
      >
        <g id="Group-5" transform="translate(89.000000, 183.000000)">
          <g id="Group-6" transform="translate(0.000000, 142.000000)">
            <mask id="mask-2" fill="white">
              <use href="#path-1" />
            </mask>
            <g id="Rectangle" />
            <g
              id="Button/Keyline-(arrow-left)-white-outline"
              mask="url(#mask-2)"
            >
              <g transform="translate(-2.000000, -2.000000)" id="Group-Copy-10">
                <g transform="translate(2.000000, 2.000000)">
                  <g id="Rectangle-Copy-11">
                    <use
                      fill="black"
                      fillOpacity="1"
                      filter="url(#filter-4)"
                      href="#path-3"
                    />
                    <rect
                      stroke="#FFFFFF"
                      strokeWidth="2"
                      strokeLinejoin="inherit"
                      fillOpacity="0"
                      fill="#FFFFFF"
                      fillRule="evenodd"
                      x="1"
                      y="1"
                      width="38"
                      height="38"
                      rx="19"
                    />
                  </g>
                  <polygon
                    id="Fill-1"
                    fill="#FF0074"
                    transform="translate(20.222222, 20.000000) scale(-1, 1) translate(-20.222222, -20.000000) "
                    points="20.2222222 12 18.8122222 13.41 24.3922222 19 12.2222222 19 12.2222222 21 24.3922222 21 18.8122222 26.59 20.2222222 28 28.2222222 20"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SScrollArrow>
)

export default ScrollArrow
