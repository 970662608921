import { useQuery } from "react-query"
import { getTrustpilotRating } from "features/api/queries"

const DEFAULT_TRUSTPILOT_STARS = 4.5
const DEFAULT_TRUSTPILOT_TRUSTSCORE = 4.6
const useTrustpilotRating = () => {
  const { data, isLoading, isError, error } = useQuery(
    ["trustpilot"],
    getTrustpilotRating,
  )
  if (isLoading) {
    return {
      stars: DEFAULT_TRUSTPILOT_STARS,
      trustScore: null,
    }
  } else if (isError || error || !data) {
    return {
      stars: DEFAULT_TRUSTPILOT_STARS,
      trustScore: DEFAULT_TRUSTPILOT_TRUSTSCORE,
    }
  } else {
    return data
  }
}

export { useTrustpilotRating }
