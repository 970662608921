export const rootFontSize = {
  large: 20,
  medium: 18,
  small: 16,
}

const breakpointSizes = {
  small: 0,
  medium: 641,
  large: 1024,
}

const mediaTemplate = (rules: string) => `@media only screen and ${rules}`

export const breakpoint = {
  small: mediaTemplate(`(max-width: ${breakpointSizes.medium - 1}px)`),
  mediumDown: mediaTemplate(`(max-width: ${breakpointSizes.large - 1}px)`),
  medium: mediaTemplate(
    `(min-width: ${breakpointSizes.medium}px) and (max-width: ${
      breakpointSizes.large - 1
    }px)`,
  ),
  mediumUp: mediaTemplate(`(min-width: ${breakpointSizes.medium}px)`),
  large: mediaTemplate(`(min-width: ${breakpointSizes.large}px)`),
}
