import React from "react"

import { ButtonColorVariantTypes, LinkButton } from "components/atoms/Button"

interface IGetStartedButton {
  className?: string
  small?: boolean
  variant?: ButtonColorVariantTypes
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  analyticsID: string
}

const GetStartedButton = ({
  className,
  small = true,
  variant,
  onClick,
  analyticsID,
}: IGetStartedButton) => (
  <LinkButton
    className={className}
    small={small}
    variant={variant ? variant : "pink"}
    href="/get-started"
    arrow={true}
    onClick={onClick}
    analyticsID={analyticsID}
  >
    Get started
  </LinkButton>
)

export default GetStartedButton
