import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import dayjs from "dayjs"

import { QUESTION_ENTRY } from "components/atoms/GetStartedQuestions"
import { FastforwardType } from "components/organisms/BuyerType"
import { IFlowStates, IQuestions } from "components/organisms/Questionnaire"
import type { RootState } from "features/store/store"
import { useAppSelector } from "features/store/store"

export interface IQuestionPersist {
  questionId: string
  selected: string
}

interface IQuestionPersistIds {
  [key: string]: IQuestionPersist
}

interface IUpdateQuestion extends IQuestionPersist {
  nextQuestionId: string | false
  setQuestionFlowState?: IFlowStates
}

type PartnerType = IPartnerPersonalDetails | false

export interface IPersonalDetails {
  takeHome: string
  DOB: string
  firstName: string
  lastName: string
  email: string
  phone: string
  extra: string
  partner?: PartnerType
}

interface IPartnerPersonalDetails {
  firstName: string
  lastName: string
  DOB: string
  takeHome: string
}

interface IGetStartedState {
  questionnaireCompleted: boolean
  questionPersist: IQuestionPersistIds
  questionFlow: string[]
  questionFlowState: IFlowStates
  personalDetails: IPersonalDetails
}

const initialState: IGetStartedState = {
  questionnaireCompleted: false,
  questionPersist: {},
  questionFlow: [QUESTION_ENTRY],
  questionFlowState: {},
  personalDetails: {
    takeHome: "",
    DOB: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    extra: "",
    partner: undefined,
  },
}

const customPriorityFlowState = (selected: string) => {
  if (selected.split("/").length === 3) {
    const today = dayjs()
    const [day, month, year] = selected.split("/")
    const targetDate = dayjs(`${year}-${month}-${day}`, "YYYY-MM-DD")
    const isLessThan6Months = targetDate.diff(today, "month") < 6

    if (isLessThan6Months) {
      return "medium"
    }
  }

  return undefined
}

export const getStartedSlice = createSlice({
  name: "getStarted",
  initialState,
  reducers: {
    updateQuestion: (state, action: PayloadAction<IUpdateQuestion>) => {
      const { questionId, selected, nextQuestionId } = action.payload
      const setQuestionFlowState = action.payload.setQuestionFlowState

      if (setQuestionFlowState) {
        for (const [key, value] of Object.entries(setQuestionFlowState)) {
          state.questionFlowState[key] = value
        }
      }

      if (
        questionId === "qRemortgage6" &&
        (!setQuestionFlowState || "priority" in setQuestionFlowState === false)
      ) {
        state.questionFlowState["priority"] = customPriorityFlowState(selected)
      }

      state.questionPersist[questionId] = {
        questionId,
        selected,
      }

      if (nextQuestionId) {
        state.questionFlow.push(nextQuestionId)
      } else {
        state.questionnaireCompleted = true
      }
      return state
    },

    popQuestionFlow: (state) => {
      state.questionFlow.pop()
      return state
    },

    reset: () => initialState,

    backToQuestionnaire: (state) => {
      state.questionnaireCompleted = false
      return state
    },

    setPersonalDetails: (state, action: PayloadAction<IPersonalDetails>) => {
      state.personalDetails = action.payload
      return state
    },

    setPartner: (state, action: PayloadAction<PartnerType>) => {
      state.personalDetails = {
        ...state.personalDetails,
        partner: action.payload,
      }
      return state
    },

    fastForwardQuestionnaire: (
      state,
      action: PayloadAction<FastforwardType>,
    ) => {
      state.questionnaireCompleted = false
      state.questionPersist = {}
      switch (action.payload) {
        case FastforwardType.firstTimeBuyer:
          state.questionFlow = ["q1", "qPurchase2", "qPurchase3"]
          state.questionPersist["q1"] = {
            questionId: "q1",
            selected: "purchase",
          }
          state.questionPersist["qPurchase2"] = {
            questionId: "qPurchase2",
            selected: "yes",
          }
          state.questionFlowState = {
            buyerType: "purchase",
          }
          return state

        case FastforwardType.homeBuyer:
          state.questionFlow = ["q1", "qPurchase2"]
          state.questionPersist["q1"] = {
            questionId: "q1",
            selected: "purchase",
          }
          state.questionFlowState = {
            buyerType: "purchase",
          }
          return state

        case FastforwardType.remortgage:
          state.questionFlow = ["q1", "qRemortgage2"]
          state.questionPersist["q1"] = {
            questionId: "q1",
            selected: "remortgage",
          }
          state.questionFlowState = {
            buyerType: "remortgage",
          }
          return state

        case FastforwardType.buyToLet:
          state.questionFlow = ["q1"]
          state.questionPersist["qPurchase3"] = {
            questionId: "qPurchase3",
            selected: "rent-out",
          }
          state.questionPersist["qRemortgage2"] = {
            questionId: "qRemortgage2",
            selected: "rent-out",
          }

          state.questionFlowState = {}
          return state
      }
    },
  },
})

export const {
  updateQuestion,
  popQuestionFlow,
  reset,
  setPersonalDetails,
  setPartner,
  backToQuestionnaire,
  fastForwardQuestionnaire,
} = getStartedSlice.actions

// Gets user's full q&a flow on submittion
export const useSelectorQuestionnaireFlow = () =>
  useAppSelector((state: RootState) => {
    const { length } = state.getStarted.questionFlow

    if (length < 1) {
      return undefined
    } else {
      return state.getStarted.questionFlow.map((questionId) => {
        return state.getStarted.questionPersist[questionId]
      })
    }
  })

// returns current question with persisted answer if there is one
export const useSelectorQuestion = (
  questions: IQuestions,
  questionId: string,
) =>
  useAppSelector((state: RootState) => {
    const persistedQuestion = state.getStarted.questionPersist[questionId]

    if (persistedQuestion) {
      return {
        ...questions[questionId],
        selected: persistedQuestion.selected,
      }
    } else {
      return questions[questionId]
    }
  })

export const useSelectorQuestionId = () =>
  useAppSelector((state: RootState) => {
    const { length } = state.getStarted.questionFlow

    if (length < 1) {
      return initialState.questionFlow[0]
    } else {
      return state.getStarted.questionFlow[length - 1]
    }
  })

export const useSelectorQuestionnaireCompleted = () =>
  useAppSelector((state: RootState) => state.getStarted.questionnaireCompleted)

export const useSelectorQuestionFlowState = () =>
  useAppSelector((state: RootState) => state.getStarted.questionFlowState)

export const useSelectorPersonalDetails = () =>
  useAppSelector((state: RootState) => state.getStarted.personalDetails)

export const useSelectorHasPartner = () =>
  useAppSelector((state: RootState) => {
    if (!state.getStarted.personalDetails.partner) {
      return state.getStarted.personalDetails.partner
    }
    return true
  })

export default getStartedSlice.reducer
