import React from "react"
import styled from "styled-components"

import { rems } from "base_css/utils/fontSize"

interface IQuotes {
  className?: string
  fill?: string
}

const SQuotes = styled.svg<IQuotes>`
  fill: ${({ theme, fill }) => (fill ? fill : theme.color.white)};
  height: ${rems(31)};
  margin-bottom: ${({ theme }) => theme.spacing.small};
`

const Quotes = ({ className, fill }: IQuotes) => (
  <SQuotes className={className} fill={fill} viewBox="0 0 31 24">
    <g id="Page-1" stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
      <g
        id="Desktop---Singlepage-scroll---v3"
        transform="translate(-729.000000, -2915.000000)"
        fill={fill}
        fillRule="nonzero"
      >
        <path
          d="M759.672852,2919.20312 C755.701478,2921.15626 753.71582,2923.45116 753.71582,2926.08789 C755.408537,2926.2832 756.808263,2926.97493 757.915039,2928.16309 C759.021815,2929.35124 759.575195,2930.72655 759.575195,2932.28906 C759.575195,2933.94923 759.038091,2935.34895 757.963867,2936.48828 C756.889643,2937.62761 755.538745,2938.19727 753.911133,2938.19727 C752.088207,2938.19727 750.509447,2937.45671 749.174805,2935.97559 C747.840163,2934.49446 747.172852,2932.69597 747.172852,2930.58008 C747.172852,2924.23239 750.720993,2919.26825 757.817383,2915.6875 L759.672852,2919.20312 Z M741.801758,2919.20312 C737.797832,2921.15626 735.795898,2923.45116 735.795898,2926.08789 C737.521167,2926.2832 738.937169,2926.97493 740.043945,2928.16309 C741.150722,2929.35124 741.704102,2930.72655 741.704102,2932.28906 C741.704102,2933.94923 741.15886,2935.34895 740.068359,2936.48828 C738.977859,2937.62761 737.618823,2938.19727 735.991211,2938.19727 C734.168285,2938.19727 732.597663,2937.45671 731.279297,2935.97559 C729.960931,2934.49446 729.301758,2932.69597 729.301758,2930.58008 C729.301758,2924.23239 732.833624,2919.26825 739.897461,2915.6875 L741.801758,2919.20312 Z"
          id="“"
        />
      </g>
    </g>
  </SQuotes>
)

export default Quotes
