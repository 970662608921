interface IReviews {
  [buyerType: string]: {
    text: string
    author: string
    location: string
    link: string
  }
}

export const reviews: IReviews = {
  main: {
    text: "1st class service from someone who genuinely cares... Having been a customer of L&C and Habito we can say with confidence that we are here to stay and would highly recommend to anyone who needs a mortgage.",
    author: "Jamie Hutchinson",
    location: "UK",
    link: "https://uk.trustpilot.com/reviews/60df0eb3f9f48702a88e9b85",
  },
  "Buy to let": {
    text: "Ben Kehoe was fantastic - always available when I needed him, quick to respond and send over info. When the vendors were having issues with their mortgage, he even offered to get involved and help them - above and beyond! I've now got my dream house and managed to get through before the stamp duty deadline - highly recommend!",
    author: "Elly Page",
    location: "UK",
    link: "https://uk.trustpilot.com/reviews/60edf75cf9f487033082cecf",
  },
  "Buying a home": {
    text: "Ben was absolutely pinnacle in our experience of buying our first home. He had our backs the entire way through and it was so reassuring to know that we had someone fighting our corner. Ben is extremely knowledgable... No exaggeration, we would not have been able to do this without him.",
    author: "Natalie Wylie",
    location: "UK",
    link: "https://uk.trustpilot.com/reviews/60ffb3edf9f487044c4967d5",
  },
  "First-time buyer": {
    text: "A great customer experience ...After having an initial conversation to understand our needs, our mortgage offer was quickly secured enabling us to have an offer accepted on our first home within days. Would 100% recommend for an efficient service with clear and helpful pointers along the way",
    author: "Ryan Furniss",
    location: "UK",
    link: "https://uk.trustpilot.com/reviews/6125eb50eb6de7284e2705dc",
  },
  Remortgage: {
    text: "As first time buyers we didn't really know what to expect from a Mortgage broker. We spoke to a few different brokers and in the end the decision was easy. ...fantastic throughout, always responsive via email and phone and really helpful in guiding us through the process. 10/10",
    author: "Charlie",
    location: "UK",
    link: "https://uk.trustpilot.com/reviews/60db1843f9f48706f85e4bd7",
  },
}
