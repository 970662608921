import React from "react"
import styled, { css } from "styled-components"

import ScrollArrow from "components/svg/ScrollArrow"

interface ICarouselArrow {
  position?: string
  onClick: () => void
}

const SScrollArrowButton = styled.button<ICarouselArrow>`
  ${({ position }) =>
    position === "right" &&
    css`
      transform: rotate(180deg);
    `}
`

const CarouselArrow = ({ position, onClick }: ICarouselArrow) => (
  <SScrollArrowButton position={position} onClick={onClick}>
    <ScrollArrow />
  </SScrollArrowButton>
)

export default CarouselArrow
