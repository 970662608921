import React, { useContext, useRef, useState } from "react"
import styled, { ThemeContext } from "styled-components"

import { rems } from "base_css/utils/fontSize"
import Chevron from "components/svg/Chevron"

const BORDER_RADIUS = rems(5)

const SAccordionGroup = styled.div`
  border-radius: ${BORDER_RADIUS};
  box-shadow: 0 ${rems(5)} ${rems(20)} 0 rgba(34, 34, 34, 0.15);
`

interface IAccordionStyle {
  isBottom: boolean
}

const SAccordion = styled.div<IAccordionStyle>`
  border: solid 1px ${({ theme }) => theme.color.grey.lightest};
  border-radius: ${({ isBottom }) => isBottom && BORDER_RADIUS};
`

const SHeader = styled.button`
  align-items: center;
  appearance: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.mediumLarge};
  width: 100%;

  &:focus {
    outline: none;
  }

  :hover {
    div,
    p {
      color: ${({ theme }) => theme.color.accent};
      transition: all 0.2s ease-in-out;
    }
  }
`

interface IContainer {
  active: boolean
  height?: string
}

const SHeaderContent = styled.div<IContainer>`
  align-items: center;
  display: flex;

  p {
    color: ${({ active, theme }) =>
      active ? theme.color.accent : theme.color.primary};
    font-weight: bold;
    margin: 0;
  }
`

const SStep = styled.div`
  color: ${({ theme }) => theme.color.accent};
  font-weight: bold;
  margin-right: ${({ theme }) => theme.spacing.large};
`

const SChevronContainer = styled.span<IContainer>`
  pointer-events: none;
  transform: ${({ active }) => (active ? "rotate(90deg)" : "rotate(270deg)")};
  transition: all 0.2s ease-in-out;
`

const SChevron = styled(Chevron)`
  color: ${({ theme }) => theme.color.accent};
`

const SContentSpace = styled.div<IContainer & IAccordionStyle>`
  background-color: ${({ theme }) => theme.color.grey.lightest};
  border-bottom-left-radius: ${({ isBottom }) => isBottom && BORDER_RADIUS};
  border-bottom-right-radius: ${({ isBottom }) => isBottom && BORDER_RADIUS};
  box-sizing: content-box;
  font-size: ${({ theme }) => theme.fontSize.small};
  max-height: ${({ height }) => height && height};
  overflow: hidden;
  padding: ${({ active, theme }) =>
    `${active ? theme.spacing.mediumLarge : 0} ${theme.spacing.large}`};
  text-align: initial;
  transition: all 0.2s ease-in-out;
`

interface IAccordionItem {
  header: string
  content: string | React.ReactElement
  isBottom: boolean
  number: number | undefined
}

const AccordionItem = ({
  header,
  content,
  isBottom,
  number,
}: IAccordionItem) => {
  const theme = useContext(ThemeContext)
  const [height, setHeight] = useState("0px")
  const [active, setActive] = useState(false)

  const contentSpace = useRef<HTMLInputElement>(null)
  const toggleAccordion = () => {
    setActive(!active)

    if (contentSpace !== null && contentSpace.current !== null) {
      setHeight(
        active
          ? "0px"
          : `calc(${contentSpace.current.scrollHeight}px + ${theme.spacing.mediumSmall} * 2)`,
      )
    }
  }

  return (
    <SAccordion isBottom={isBottom}>
      <SHeader onClick={toggleAccordion}>
        <SHeaderContent active={active}>
          {number && <SStep>{number}.</SStep>}
          <p>{header}</p>
        </SHeaderContent>
        <SChevronContainer active={active}>
          <SChevron />
        </SChevronContainer>
      </SHeader>

      <SContentSpace
        ref={contentSpace}
        height={height}
        active={active}
        isBottom={isBottom}
      >
        {content}
      </SContentSpace>
    </SAccordion>
  )
}

type IStep = {
  header: string
  content: string | React.ReactElement
}

export type ISteps = IStep[]

interface IAccordion {
  data: ISteps
  numbered: boolean
}

const Accordion = ({ data, numbered }: IAccordion) => (
  <SAccordionGroup>
    {data.map(({ header, content }, index) => (
      <AccordionItem
        key={index}
        isBottom={index === data.length - 1}
        header={header}
        content={content}
        number={numbered ? index + 1 : undefined}
      />
    ))}
  </SAccordionGroup>
)

export default Accordion
