/**
 * @description Google analytics events helper file
 * to be used with snakecase per GAs required formatting
 */

import config from "config"

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    gtag: any
  }
}

export const anonymiseData = () => {
  window.gtag("config", config.analytics.googleAnalytics, {
    // eslint-disable-next-line camelcase
    anonymize_ip: true,
  })
}

export const setUserCompanyProperty = (companyName?: string) => {
  window.gtag("set", "user_properties", {
    // eslint-disable-next-line camelcase
    user_company: companyName,
  })
}

export const setIsIntroducerProperty = () => {
  window.gtag("set", "user_properties", {
    // eslint-disable-next-line camelcase
    user_company_type: "introducer",
  })
}

export const clickEvent = (id: string) => {
  window.gtag("event", "click", {
    id,
  })
}

export const calculatorEngagementEvent = (id: string) => {
  window.gtag("event", "calculator_engagement", {
    id,
  })
}

export const formSubmittedEvent = (id: string) => {
  window.gtag("event", "form_submitted", {
    id,
  })
}

export const formEngagementEvent = (id: string) => {
  window.gtag("event", "form_engagement", {
    id,
  })
}

export const questionnaireFlowEvent = (
  id: string,
  selected: number | string,
) => {
  window.gtag("event", "questionnaire_flow", {
    id,
    selected,
  })
}
