import React from "react"
import styled from "styled-components"

import AngledBlock from "components/atoms/AngledBlock"
import Carousel from "components/molecules/Carousel"
import { Cell, Container, Grid } from "components/atoms/Grid"
import Quote from "components/atoms/Quote"
import { reviews } from "components/atoms/TrustpilotReviews"
import TrustpilotReviews from "components/molecules/TrustpilotReviews"

interface IReviewSectionStyle {
  className?: string
  isDark: boolean
  buyerType?: string
}

const SAngledBlock = styled(AngledBlock)<{ isDark: boolean }>`
  background-color: ${({ theme, isDark }) =>
    isDark ? theme.color.primary : theme.color.grey.darkest};
`

const STrustpilotCell = styled(Cell)`
  color: ${({ theme }) => theme.color.white};
  margin-top: ${({ theme }) => theme.spacing.medium};
`

const SQuoteContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacing.medium};
`

const ReviewSection = ({
  className,
  isDark,
  buyerType = "main",
}: IReviewSectionStyle) => {
  return (
    <SAngledBlock className={className} isDark={isDark}>
      <Container>
        <Grid>
          <STrustpilotCell width={12} lgLeft={2} lgWidth={5} center={true}>
            <TrustpilotReviews isDark={isDark} />
          </STrustpilotCell>

          {buyerType === "main" ? (
            <Cell width={12} lgWidth={6} lgLeft={7} middle={true}>
              <Carousel>
                {Object.keys(reviews).map((key) => (
                  <Cell width={12} mdWidth={12} middle={true} key={key}>
                    <SQuoteContainer>
                      <Quote
                        href={reviews[key].link}
                        author={reviews[key].author}
                        location={reviews[key].location}
                      >
                        {reviews[key].text}
                      </Quote>
                    </SQuoteContainer>
                  </Cell>
                ))}
              </Carousel>
            </Cell>
          ) : (
            <Cell width={12} lgWidth={5} lgLeft={7}>
              <Quote
                author={reviews[buyerType].author}
                location={reviews[buyerType].location}
                href={reviews[buyerType].link}
              >
                {reviews[buyerType].text}
              </Quote>
            </Cell>
          )}
        </Grid>
      </Container>
    </SAngledBlock>
  )
}

export default ReviewSection
