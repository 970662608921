import React, { useEffect } from "react"
import { useRouter } from "next/router"
import * as ga from "features/analytics/ga"

export interface ICompanyContext {
  companyName: string | undefined
  companyPath: string | undefined
  logoUrl: string | undefined
  webinarUrl: string | undefined
  campaignId: string | undefined
  introducer: boolean
}

const initialCompanyContext: ICompanyContext = {
  companyName: undefined,
  companyPath: undefined,
  logoUrl: undefined,
  webinarUrl: undefined,
  campaignId: undefined,
  introducer: false,
}

export const CompanyContext = React.createContext(initialCompanyContext)

interface ICompanyContextProvider {
  children: React.ReactNode
}

const CompanyContextProvider = ({ children }: ICompanyContextProvider) => {
  const { query } = useRouter()
  const {
    companyName,
    companyPath,
    logoUrl,
    webinarUrl,
    campaignId,
    introducer,
  } = query

  const companyContext: ICompanyContext = {
    companyName: companyName as string | undefined,
    companyPath: companyPath as string | undefined,
    logoUrl: logoUrl as string | undefined,
    webinarUrl: webinarUrl as string | undefined,
    campaignId: campaignId as string | undefined,
    introducer: introducer === "true",
  }

  useEffect(() => {
    ga.setUserCompanyProperty(companyContext.companyName)
    if (companyContext.introducer) {
      ga.setIsIntroducerProperty()
    }
  }, [])

  return (
    <CompanyContext.Provider value={companyContext}>
      {children}
    </CompanyContext.Provider>
  )
}

export default CompanyContextProvider
