import { DefaultTheme } from "styled-components"

import color from "themes/color"
import fontFamily from "themes/fontFamily"
import fontSize from "themes/fontSize"
import spacing from "themes/spacing"
import zIndex from "themes/zIndex"

const theme: DefaultTheme = {
  color,
  fontFamily,
  fontSize,
  spacing,
  zIndex,
}

export default theme
