const color = {
  primary: "#0E1E0F",
  accent: "#FF1E7E",
  white: "#FFFFFF",
  black: "#1d1d1b",
  grey: {
    darkest: "#99a099",
    dark: "#c0c5c1",
    light: "#eaebea",
    lightest: "#f1f2f1",
  },
  state: {
    error: "#F35858",
    success: "#1DD66C",
    warning: "#E2B661",
    info: "#b6d4fe",
  },
  social: {
    facebook: "#3b5998",
    linkedIn: "#007AB9",
    twitter: "#55ACEE",
  },
}

export default color
