const zIndex = {
  // satellite: 5000,
  skyscraper: 1000,
  towerBlock: 500,
  house: 200,
  bungalow: 100,
  // shed: 50,
  postbox: 10,
  curb: 1,
  pavement: 0,
  // pothole: -10,
  // ditch: -20,
  // sewer: -100,
  // mine: -300,
  // seabed: -1000
}

export default zIndex
