import React from "react"
import styled from "styled-components"

interface IInnerBlock {
  padded?: boolean
}

interface IOuterBlock extends IInnerBlock {
  top?: "incline" | "decline"
  bottom?: "incline" | "decline"
  color?: string
}

interface IAngledBlock extends IOuterBlock {
  children: React.ReactNode
  className?: string
  id?: string
}

const ANGLE = "10vw"

const SAngledBlockOuter = styled.section<IOuterBlock>`
  background-color: ${({ color, theme }) =>
    (color === "pink" && theme.color.accent) ||
    (color === "purple" && theme.color.primary) ||
    theme.color.primary};
  ${({ padded, top }) => padded && top && `padding-top: ${ANGLE}`};
  ${({ padded, bottom }) => padded && bottom && `padding-bottom: ${ANGLE}`};
`

const SAngledBlockInner = styled.div<IInnerBlock>`
  padding-bottom: ${({ padded, theme }) => padded && theme.spacing.xLarge};
  padding-top: ${({ padded, theme }) => padded && theme.spacing.xLarge};
`

const AngledBlock = ({
  bottom,
  children,
  className,
  id,
  padded = true,
  top,
}: IAngledBlock) => (
  <SAngledBlockOuter
    className={className}
    top={top}
    bottom={bottom}
    padded={padded}
  >
    <SAngledBlockInner id={id} padded={padded}>
      {children}
    </SAngledBlockInner>
  </SAngledBlockOuter>
)

export default AngledBlock
