import React from "react"
import styled from "styled-components"

import { rems } from "base_css/utils/fontSize"

interface IArrowStyle {
  small?: boolean
}

interface IArrow extends IArrowStyle {
  className?: string
}

const SArrow = styled.svg<IArrowStyle>`
  height: ${({ small }) => (small ? rems(12) : rems(16))};
`

const SGroup = styled.g`
  fill: currentColor;
`

export const Arrow = ({ className, small }: IArrow) => (
  <SArrow className={className} small={small} viewBox="0 0 17 16">
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <SGroup
        id="Button/Round-Icon---Get-started"
        transform="translate(-122.000000, -14.000000)"
      >
        <g id="Group-Copy-8">
          <polygon
            id="Fill-1"
            points="130.222222 14 128.812222 15.41 134.392222 21 122.222222 21 122.222222 23 134.392222 23 128.812222 28.59 130.222222 30 138.222222 22"
          />
        </g>
      </SGroup>
    </g>
  </SArrow>
)

export default Arrow
