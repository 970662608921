import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"

import { breakpoint } from "base_css/vars"
import CarouselArrow from "components/svg/CarouselArrow"
import CarouselSlide from "components/svg/CarouselSlide"
import { Cell, Container, Grid } from "components/atoms/Grid"

interface ICarousel {
  children: JSX.Element[]
  className?: string
}

const SCarousel = styled(Container)`
  display: flex;
  overflow-x: hidden;
`

interface IArrowCell {
  position?: string
}

const SArrowCell = styled(Cell)<IArrowCell>`
  align-items: center;
  display: flex;
  justify-content: ${({ position }) =>
    position === "right" ? "flex-end" : "flex-start"};
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.postbox};

  ${breakpoint.small} {
    left: ${({ theme, position }) =>
      position === "right" ? theme.spacing.mediumSmall : "auto"};
    right: ${({ position, theme }) =>
      position === "right" ? "auto" : theme.spacing.mediumSmall};
  }
`

interface ICarouselSlide {
  currentSlide: number
}

const SCarouselSlides = styled(Cell)<ICarouselSlide>`
  display: flex;
  ${({ currentSlide }) =>
    currentSlide &&
    css`
      transform: translateX(-${currentSlide * 100}%);
    `};
  transition: all 0.5s ease;

  ${breakpoint.small} {
    align-items: flex-start;
  }
`

const Carousel = ({ children, className }: ICarousel) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [slideClicked, setSlideClicked] = useState<boolean>(false)

  useEffect(() => {
    if (slideClicked) {
      return
    } else {
      const interval = setInterval(
        () => setCurrentSlide((currentSlide + 1) % formattedSlides.length),
        10000,
      )
      return () => clearInterval(interval)
    }
  }, [currentSlide, children])

  const formattedSlides = children.map((slide, index) => (
    <CarouselSlide active={currentSlide === index} key={index}>
      {slide}
    </CarouselSlide>
  ))

  return (
    <SCarousel className={className}>
      <Grid>
        <SArrowCell width={1} mdWidth={1} mdLeft={1}>
          <CarouselArrow
            onClick={() => {
              setCurrentSlide(
                (currentSlide - 1 + formattedSlides.length) %
                  formattedSlides.length,
              )
              setSlideClicked(true)
            }}
          />
        </SArrowCell>
        <SCarouselSlides currentSlide={currentSlide} width={10}>
          {formattedSlides}
        </SCarouselSlides>
        <SArrowCell width={1} mdWidth={1} mdLeft={12} position="right">
          <CarouselArrow
            onClick={() => {
              setCurrentSlide((currentSlide + 1) % formattedSlides.length)
              setSlideClicked(true)
            }}
            position="right"
          />
        </SArrowCell>
      </Grid>
    </SCarousel>
  )
}

export default Carousel
