export type ClientConfigType = typeof ClientConfig

interface ISentry {
  enabled: boolean
  dsn?: string
}

interface IServices {
  contentServer: string
  backend?: string
  novaService: string
}

interface IAnalytics {
  googleAnalytics?: string
}

interface ICalendly {
  highPriority: string
  default: string
}
interface IHelpscout {
  id: string
}

class ClientConfig {
  readonly ENV: "local" | "development" | "production" | "test"
  readonly NODE_ENV: NodeJS.ProcessEnv["NODE_ENV"]
  readonly isProduction: boolean
  readonly debug: boolean
  readonly isHTTPS: boolean

  readonly sentry: ISentry
  readonly calendly: ICalendly
  baseUrl: string
  services: IServices
  analytics: IAnalytics
  helpscout: IHelpscout

  constructor(e: NodeJS.ProcessEnv) {
    this.NODE_ENV = e?.NODE_ENV as ClientConfig["NODE_ENV"]
    this.ENV = e?.PUBLIC_ENVIRONMENT as ClientConfig["ENV"]
    this.isProduction = this.ENV === "production"
    this.debug = !this.isProduction && this.NODE_ENV !== "production"
    this.baseUrl = (e?.PUBLIC_HOST as string) ?? ""
    this.isHTTPS = this.baseUrl.includes("https")

    this.sentry = {
      enabled: this.isProduction,
      dsn: e?.PUBLIC_SENTRY_DSN as string,
    }

    this.services = {
      contentServer:
        (e?.PUBLIC_CONTENT_SERVER as string) || "http://localhost:1337/graphql",
      backend: e?.PUBLIC_BACKEND_HOST as string,
      novaService: e?.PUBLIC_NOVA_SERVICE_HOST as string,
    }

    this.analytics = {
      googleAnalytics: e?.PUBLIC_GOOGLE_ANALYTICS as string,
    }
    this.calendly = {
      highPriority: "https://calendly.com/nw-mortgages/30min-intro",
      default: "https://calendly.com/nw-mortgages/30-minute-introductory-call",
    }
    this.helpscout = {
      id: "d3722d48-4dd4-4430-92df-a1e3b1c4b280",
    }
  }
}

export default ClientConfig
