import React from "react"
import styled, { useTheme } from "styled-components"

import Image from "next/image"

import { rems } from "base_css/utils/fontSize"
import { breakpoint } from "base_css/vars"
import { LinkButton } from "components/atoms/Button"
import { Cell, Container, Grid } from "components/atoms/Grid"
import { FastforwardType, IBuyerType } from "components/organisms/BuyerType"

import { fastForwardQuestionnaire } from "features/slices/getStartedSlice"
import { useAppDispatch } from "features/store/store"

const SH1 = styled.h1`
  color: ${({ theme }) => theme.color.primary};
`

const SImageWrapper = styled.div`
  img {
    height: unset !important;
    min-height: 0%;
  }

  ${breakpoint.small} {
    display: none;
  }
`

const SImageWrapperCell = styled(Cell)`
  min-height: ${rems(200)};

  ${breakpoint.small} {
    display: none;
  }
`

interface IBuyerTypeHero {
  name: IBuyerType["name"]
  description: IBuyerType["description"]
  heroImage: IBuyerType["heroImage"]
  fastForwardType: FastforwardType
}

const BuyerTypeHero = ({
  name,
  description,
  heroImage,
  fastForwardType,
}: IBuyerTypeHero) => {
  const dispatch = useAppDispatch()
  const theme = useTheme()

  const onCTAClick = () => {
    dispatch(fastForwardQuestionnaire(fastForwardType))
  }

  return (
    <Container
      marginBottom={theme.spacing.large}
      marginTop={theme.spacing.large}
      lgMarginBottom={theme.spacing.xLarge}
      lgMarginTop={theme.spacing.xLarge}
    >
      <Grid columnGap={theme.spacing.xLarge}>
        <Cell width={12} mdWidth={6} mdLeft={2} middle={true}>
          <SH1>{name}</SH1>
          {description}
          <LinkButton
            small={true}
            href="/get-started"
            arrow={true}
            onClick={onCTAClick}
            variant={"pink"}
            analyticsID={`${name} BuyerTypeHero GetStartedButton`}
          >
            Get started {fastForwardType}
          </LinkButton>
        </Cell>
        <SImageWrapperCell width={12} mdWidth={4} mdLeft={8}>
          <SImageWrapper
            style={{ width: "100%", height: "100%", position: "relative" }}
          >
            <Image
              alt={`${name} type of buyer ${heroImage}`}
              src={heroImage}
              layout="fill"
            />
          </SImageWrapper>
        </SImageWrapperCell>
      </Grid>
    </Container>
  )
}

export default BuyerTypeHero
