import { ImageProps } from "next/image"
import React, { useContext } from "react"
import styled, { useTheme } from "styled-components"

import { h2 } from "base_css/mixins"
import { rems } from "base_css/utils/fontSize"
import Accordion, { ISteps } from "components/atoms/Accordion"
import { Cell, Container, Grid } from "components/atoms/Grid"
import BuyerTypeHero from "components/molecules/BuyerTypeHero"
import ReviewSection from "components/molecules/ReviewSection"
import GetStartedSection from "components/organisms/GetStartedSection"
import ClickTracker from "features/analytics/ClickTracker"

import { CompanyContext } from "companyContext"
import InlineCalculatorSection from "components/organisms/InlineCalculatorSection"

const FEATURE_SPACING = rems(300)

const SGrid = styled(Grid)`
  grid-gap: ${({ theme }) => theme.spacing.medium};
  row-gap: ${({ theme }) => theme.spacing.medium};
`

const SHeader = styled.h2`
  ${h2} /* stylelint-disable-next-line */
  color: ${({ theme }) => theme.color.accent};
  text-align: center;
`

export const SFeatureContainer = styled.div`
  margin-bottom: -${FEATURE_SPACING};
  margin-top: ${({ theme }) => theme.spacing.xLarge};
  z-index: ${({ theme }) => theme.zIndex.curb};
`

export const SReviewSection = styled(ReviewSection)`
  padding-top: ${FEATURE_SPACING};
`

export enum FastforwardType {
  firstTimeBuyer = "as a first time buyer",
  homeBuyer = "as a home buyer",
  remortgage = "with your remortgage",
  buyToLet = "as buy to let",
}

export interface IBuyerType {
  name: string
  description: JSX.Element
  calculator: JSX.Element
  featureCard: JSX.Element
  heroImage: ImageProps["src"]
  fastForwardType: FastforwardType
  getStepsContent: (isCompanyUser: boolean) => ISteps
}

const BuyerType = ({
  name,
  description,
  calculator,
  featureCard,
  heroImage,
  fastForwardType,
  getStepsContent,
}: IBuyerType) => {
  const theme = useTheme()
  const isCompanyUser = useContext(CompanyContext).companyName !== undefined

  return (
    <>
      <BuyerTypeHero
        name={name}
        description={description}
        fastForwardType={fastForwardType}
        heroImage={heroImage}
      />
      <SFeatureContainer>{featureCard}</SFeatureContainer>
      <SReviewSection isDark={false} buyerType={name} />
      <InlineCalculatorSection>{calculator}</InlineCalculatorSection>

      <GetStartedSection isDark={true} />
      <Container
        marginBottom={theme.spacing.large}
        marginTop={theme.spacing.large}
      >
        <SHeader>How does it work?</SHeader>
        <SGrid>
          <ClickTracker analyticsID="BuyerType Accordion">
            <Cell width={12}>
              <Accordion
                data={getStepsContent(isCompanyUser)}
                numbered={true}
              />
            </Cell>
          </ClickTracker>
        </SGrid>
      </Container>
    </>
  )
}

export default BuyerType
