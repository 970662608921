import { css } from "styled-components"

const allTextElements = css`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  margin-top: 0;
`

const allHeaders = css`
  font-weight: 400;
  letter-spacing: 0.01em;
`

const largeHeaders = css`
  font-family: ${({ theme }) => theme.fontFamily.header};
  line-height: 1.2;
`

const smallHeaders = css`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: bold;
  line-height: inherit;
`

export const h1 = css`
  ${allTextElements} /* stylelint-disable-next-line */
  ${allHeaders} /* stylelint-disable-next-line */
  ${largeHeaders} /* stylelint-disable-next-line */
  font-size: ${({ theme }) => theme.fontSize.h1};
`

export const h2 = css`
  ${allTextElements} /* stylelint-disable-next-line */
  ${allHeaders} /* stylelint-disable-next-line */
  ${largeHeaders} /* stylelint-disable-next-line */
  font-size: ${({ theme }) => theme.fontSize.h2};
`

export const h3 = css`
  ${allTextElements} /* stylelint-disable-next-line */
  ${allHeaders} /* stylelint-disable-next-line */
  ${largeHeaders} /* stylelint-disable-next-line */
  font-size: ${({ theme }) => theme.fontSize.h3};
`

export const h4 = css`
  ${allTextElements} /* stylelint-disable-next-line */
  ${allHeaders} /* stylelint-disable-next-line */
  ${smallHeaders} /* stylelint-disable-next-line */
  font-size: ${({ theme }) => theme.fontSize.h4};
`

export const h5 = css`
  ${allTextElements} /* stylelint-disable-next-line */
  ${allHeaders} /* stylelint-disable-next-line */
  ${smallHeaders} /* stylelint-disable-next-line */
  font-size: ${({ theme }) => theme.fontSize.h5};
`

export const h6 = css`
  ${allTextElements} /* stylelint-disable-next-line */
  ${allHeaders} /* stylelint-disable-next-line */
  ${smallHeaders} /* stylelint-disable-next-line */
  font-size: ${({ theme }) => theme.fontSize.body};
`

export const p = css`
  ${allTextElements}
`

export const ol = css`
  ${allTextElements}
`

export const ul = css`
  ${allTextElements}
`

export const inlineLink = css`
  color: ${({ theme }) => theme.color.accent};
  text-decoration: underline;
`
