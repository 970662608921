import React from "react"
import styled, { css } from "styled-components"

import { rems } from "base_css/utils/fontSize"

const pulse = css`
  animation: pulse 1.2s ease-in-out infinite;
  background-image: ${({ theme }) => css`
    linear-gradient(
      -90deg,
      ${theme.color.grey.dark} 0%,
      ${theme.color.grey.lightest} 50%,
      ${theme.color.grey.dark} 100%
    )
  `};
  background-size: 400% 400%;
  opacity: 0.4;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`

const SSkeletonPulse = styled.span`
  ${pulse}
  display: inline-block;
  height: 100%;
  width: 100%;
`

interface ISkeletonLine {
  className?: string
  width?: string
}

const SSkeletonLine = styled(SSkeletonPulse)<ISkeletonLine>`
  width: ${({ width }) => (width ? width : "5.5em")};
  border-radius: ${rems(5)};
  height: 100%;

  &::before {
    content: "\00a0";
  }
`

const SHeaderSkeleton = styled(SSkeletonLine)`
  height: ${({ theme }) => theme.fontSize.h3};
`

const SBodySkeleton = styled(SSkeletonLine)`
  height: ${({ theme }) => theme.fontSize.body};
`

interface ISkeletonCircle {
  className?: string
  width: string
}

const SSkeletonCircle = styled(SSkeletonPulse)<ISkeletonCircle>`
  border-radius: 100%;
  width: ${({ width }) => width};
`

interface ISkeletonButton {
  className?: string
  small?: boolean
}

const SSkeletonButton = styled.button<ISkeletonButton>`
  ${pulse}
  border-color: transparent;
  border-radius: ${rems(100)};
`

export const HeaderSkeleton = ({ className, width }: ISkeletonLine) => (
  <SHeaderSkeleton className={className} width={width} />
)

export const BodySkeleton = ({ className, width }: ISkeletonLine) => (
  <SBodySkeleton className={className} width={width} />
)

export const SkeletonLine = ({ className, width }: ISkeletonLine) => (
  <SSkeletonLine className={className} width={width} />
)

export const SkeletonCircle = ({ className, width }: ISkeletonCircle) => (
  <SSkeletonCircle className={className} width={width} />
)

export const SkeletonButton = ({ className, small }: ISkeletonButton) => (
  <SSkeletonButton className={className} small={small}>
    &nbsp;
  </SSkeletonButton>
)
