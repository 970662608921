import React from "react"
import styled, { css } from "styled-components"

import { rems } from "base_css/utils/fontSize"

interface ICheveron {
  className?: string
  variant?: "up" | "down" | "left" | "right"
}

const SChevron = styled.svg<ICheveron>`
  height: ${rems(10)};
  ${({ variant }) =>
    (variant === "up" &&
      css`
        --rotation: 90deg;
        transform: rotate(var(--rotation));
      `) ||
    (variant === "right" &&
      css`
        --rotation: 180deg;
        transform: rotate(var(--rotation));
      `) ||
    (variant === "down" &&
      css`
        --rotation: 270deg;
        transform: rotate(var(--rotation));
      `)}
`

const SGroup = styled.g`
  color: ${({ theme }) => theme.color.primary};
  stroke: currentColor;
`

const Chevron = ({ className, variant }: ICheveron) => (
  <SChevron viewBox="0 0 6 10" className={className} variant={variant}>
    <g
      id="Navigation"
      stroke="none"
      strokeWidth="1"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="bevel"
    >
      <SGroup
        id="Breadcrumb/Mobile"
        transform="translate(-20.000000, -11.000000)"
      >
        <g
          id="Icon/Chevron/Light"
          transform="translate(23.000000, 16.000000) rotate(180.000000) translate(-23.000000, -16.000000) translate(19.000000, 12.000000)"
        >
          <g id="Close" transform="translate(2.500000, 0.500000)">
            <path d="M0,0 L3.5,3.5" id="Line-3" strokeWidth="2" />
            <path
              d="M0,3.5 L3.5,7"
              id="Line-3-Copy"
              strokeWidth="2"
              transform="translate(1.750000, 5.250000) rotate(-270.000000) translate(-1.750000, -5.250000) "
            />
          </g>
        </g>
      </SGroup>
    </g>
  </SChevron>
)

export default Chevron
