import { rems } from "base_css/utils/fontSize"

const fontSize = {
  body: rems(16),
  h1: rems(38),
  h2: rems(32),
  h3: rems(27),
  h4: rems(23),
  h5: rems(19),
  small: rems(14),
  smaller: rems(12),
  xSmall: rems(10),
}

export default fontSize
