/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as Sentry from "@sentry/browser"
import React from "react"
import styled, { css, DefaultTheme } from "styled-components"

import { rems } from "base_css/utils/fontSize"
import { CustomLink } from "components/atoms/CustomLink"
import Arrow from "components/svg/Arrow"
import Spinner from "components/svg/Spinner"
import ClickTracker from "features/analytics/ClickTracker"

export const BORDER_RADIUS = rems(5)

interface IButtonFeatures {
  arrow?: boolean
  small?: boolean
  loading?: boolean
  arrowReversed?: boolean
  testId?: string
}

interface IAnalytics {
  analyticsID?: string
}

interface IButtonInner {
  children: React.ReactNode
  arrow?: boolean
  arrowReversed?: boolean
  small?: boolean
  CustomCta?: React.FC
  loading?: boolean
}

interface IButton extends IAnalytics, IButtonFeatures {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  children: React.ReactNode
  className?: string
  testId?: string
  variant?: ButtonColorVariantTypes
  active?: boolean
}

interface IStyleProps {
  arrow?: boolean
  CustomCta?: any
  small?: boolean
  variant?: ButtonColorVariantTypes
}

interface IShow {
  show: boolean
}

interface ISpinner extends IShow {
  small?: boolean
}

export type ButtonColorVariantTypes =
  | "primary"
  | "secondary"
  | "pink"
  | "pinkSecondary"

const getButtonVariantStyle = (
  theme: DefaultTheme,
  variant?: ButtonColorVariantTypes,
) => {
  const primary = {
    backgroundColor: theme.color.primary,
    borderColor: theme.color.primary,
    textColor: theme.color.white,
  }

  switch (variant) {
    case "primary":
      return primary
    case "secondary":
      return {
        backgroundColor: theme.color.white,
        borderColor: theme.color.primary,
        textColor: theme.color.primary,
      }
    case "pink":
      return {
        backgroundColor: theme.color.accent,
        borderColor: theme.color.accent,
        textColor: theme.color.white,
      }
    case "pinkSecondary":
      return {
        backgroundColor: theme.color.white,
        borderColor: theme.color.accent,
        textColor: theme.color.accent,
      }
    default:
      return primary
  }
}

const getHoverStyling = (
  active: boolean | undefined,
  {
    backgroundColor,
    textColor,
    variant,
  }: {
    backgroundColor: string
    textColor: string
    variant?: ButtonColorVariantTypes
  },
) => {
  if (active) {
    return ""
  }
  if (variant?.toLowerCase().includes("secondary")) {
    return `
    @media (hover: hover) {
      &:hover:not([disabled]) {
        background-color: ${textColor};
        color: ${backgroundColor};
        border: 2px solid ${backgroundColor};
      }
    }
  `
  }

  return `
  @media (hover: hover) {
    &:hover:not([disabled]) { background-color: ${textColor};
      color: ${backgroundColor};
      border: 2px solid ${backgroundColor};
    }
  }
`
}

export const buttonVariantStyle = css<IButton>`
  ${({ variant, theme, active }) => {
    const { backgroundColor, borderColor, textColor } = getButtonVariantStyle(
      theme,
      variant,
    )

    return `
      border: 2px solid ${borderColor};
      background-color: ${backgroundColor};
      color: ${textColor};

      &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }

      ${getHoverStyling(active, { backgroundColor, textColor, variant })}
    `
  }}
`

export const buttonStyle = css<IButton>`
  ${buttonVariantStyle} /* stylelint-disable-next-line order/properties-alphabetical-order */
  align-items: center;
  border-radius: ${BORDER_RADIUS};
  cursor: pointer;
  display: inline-flex;
  font-size: ${({ theme }) => theme.fontSize.body};
  justify-content: center;
  padding: ${({ theme }) => `${theme.spacing.xSmall} ${theme.spacing.small}`};
  text-decoration: none;

  &:focus {
    outline: 1px solid ${({ theme }) => theme.color.primary};
  }
`

const SFormButton = styled.button<IStyleProps>`
  ${buttonStyle as any}
  /* stylelint-disable */
    ${({ disabled, theme }) =>
    disabled &&
    css`
      border: 1px solid ${theme.color.grey.darkest};
      background-color: ${theme.color.grey.lightest};
      color: ${theme.color.grey.darkest};
      pointer-events: none;

      &:hover {
        border: 1px solid ${theme.color.grey.darkest};
        background-color: ${theme.color.grey.lightest};
        color: ${theme.color.grey.darkest};
      }
    `};
  /* stylelint-enable */
`

const SButtonInnerWrapper = styled.div<IShow>`
  align-items: center;
  display: flex;
  opacity: ${({ show }) => (show ? 1 : 0)};
`

const SLinkButton = styled(CustomLink)<IButton>`
  ${buttonStyle as any}
`

const SExternalLinkButton = styled.a<IButton>`
  ${buttonStyle as any}
`

const SButton = styled.button``

const SButtonStyled = styled.button<IButton>`
  ${buttonStyle as any}
`

const SButtonInner = styled.span`
  display: flex;

  /* baseline font fix */
  margin-top: ${rems(2)};
`

const SArrow = styled(Arrow)`
  margin-left: ${(props) => props.theme.spacing.medium};
`

const SArrowReversed = styled(Arrow)`
  margin-right: ${({ theme }) => theme.spacing.small};
  transform: rotate(180deg);
  transition: margin ease 0.3s;
`

const SSpinner = styled(Spinner)<ISpinner>`
  margin-top: ${rems(3)};
  opacity: ${({ show }) => (show ? 1 : 0)};
  position: absolute;
  top: ${({ small, theme }) =>
    small === true ? `${rems(8)}` : `${theme.spacing.small}`};
`

const SBackButton = styled.button`
  align-items: center;
  color: ${({ theme }) => theme.color.primary};
  display: flex;
  transition: margin ease 0.3s;

  @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.color.accent};
      margin-left: -${rems(5)};

      ${SArrowReversed} {
        margin-right: calc(${rems(5)} + ${({ theme }) => theme.spacing.small});
      }
    }
  }
`

const ButtonInner = ({
  children,
  arrow,
  arrowReversed,
  CustomCta,
  small,
  loading,
}: IButtonInner) => {
  try {
    if (arrow && CustomCta) {
      throw new Error(
        "You cannot pass both an 'arrow' prop and 'CustomCta' prop",
      )
    } else {
      return (
        <>
          <SButtonInnerWrapper show={!loading}>
            {arrowReversed && <SArrowReversed small={small} />}
            <SButtonInner>{children}</SButtonInner>
            {arrow && <SArrow small={small} />}
            {CustomCta && <CustomCta />}
          </SButtonInnerWrapper>

          <SSpinner show={Boolean(loading)} />
        </>
      )
    }
  } catch (error) {
    Sentry.captureException(error)
    return null
  }
}

const ButtonStyled = ({
  children,
  onClick,
  className,
  small,
  arrow,
  analyticsID,
  testId,
  variant,
}: IButton) => (
  <ClickTracker analyticsID={analyticsID}>
    <SButtonStyled
      className={className}
      onClick={onClick}
      small={small}
      data-testid={testId}
      variant={variant}
    >
      <ButtonInner arrow={arrow} small={small}>
        {children}
      </ButtonInner>
    </SButtonStyled>
  </ClickTracker>
)

const Button = ({
  children,
  onClick,
  className,
  analyticsID,
  testId,
}: IButton) => (
  <ClickTracker analyticsID={analyticsID}>
    <SButton className={className} onClick={onClick} data-testid={testId}>
      <ButtonInner>{children}</ButtonInner>
    </SButton>
  </ClickTracker>
)

interface IFormButton extends IStyleProps, IAnalytics {
  children: React.ReactNode
  className?: string
  disabled?: boolean
  loading?: boolean
}

const FormButton = ({
  arrow,
  children,
  className,
  CustomCta,
  small,
  variant,
  analyticsID,
  disabled,
  loading,
}: IFormButton) => (
  <ClickTracker analyticsID={analyticsID}>
    <SFormButton
      className={className}
      small={small}
      type="submit"
      variant={variant}
      disabled={disabled || loading}
    >
      <ButtonInner
        arrow={arrow}
        CustomCta={CustomCta}
        small={small}
        loading={loading}
      >
        {children}
      </ButtonInner>
    </SFormButton>
  </ClickTracker>
)

interface ILinkProps extends IStyleProps, IAnalytics {
  children: React.ReactNode
  className?: string
  onClick?: (event: any) => void
  href: string
}

const LinkButton = ({
  arrow,
  children,
  className,
  CustomCta,
  onClick,
  small,
  href,
  variant,
  analyticsID,
}: ILinkProps) => (
  <ClickTracker analyticsID={analyticsID}>
    <SLinkButton
      className={className}
      onClick={onClick}
      small={small}
      href={href}
      variant={variant}
    >
      <ButtonInner arrow={arrow} CustomCta={CustomCta} small={small}>
        {children}
      </ButtonInner>
    </SLinkButton>
  </ClickTracker>
)

interface IExternalLinkProps extends IStyleProps, IAnalytics {
  children: React.ReactNode
  className?: string
  href: string
  onClick?: (event: any) => void
  rel?: string
}

const ExternalLinkButton = ({
  arrow,
  children,
  className,
  CustomCta,
  href,
  onClick,
  rel,
  small,
  variant,
  analyticsID,
}: IExternalLinkProps) => (
  <ClickTracker analyticsID={analyticsID}>
    <SExternalLinkButton
      className={className}
      href={href}
      onClick={onClick}
      rel={rel}
      small={small}
      target="_blank"
      variant={variant}
    >
      <ButtonInner arrow={arrow} CustomCta={CustomCta} small={small}>
        {children}
      </ButtonInner>
    </SExternalLinkButton>
  </ClickTracker>
)

const BackButton = ({ children, className, onClick }: IButton) => (
  <ClickTracker analyticsID="BackButton">
    <SBackButton
      className={className}
      onClick={(e: any) => {
        e.currentTarget.blur()
        if (onClick) {
          onClick(e)
        }
      }}
      type="button"
    >
      <ButtonInner arrowReversed={true} small={true}>
        {children}
      </ButtonInner>
    </SBackButton>
  </ClickTracker>
)

export {
  BackButton,
  Button,
  ButtonStyled,
  ExternalLinkButton,
  FormButton,
  LinkButton,
}
