import { rems } from "base_css/utils/fontSize"

const BASE_SPACING = 5

const remsBaseSpacing = (integer: number) => rems(BASE_SPACING * integer)

const spacing = {
  xSmall: `${remsBaseSpacing(1)}`,
  small: `${remsBaseSpacing(2)}`,
  mediumSmall: `${remsBaseSpacing(3)}`,
  medium: `${remsBaseSpacing(4)}`,
  mediumLarge: `${remsBaseSpacing(6)}`,
  large: `${remsBaseSpacing(8)}`,
  xLarge: `${remsBaseSpacing(12)}`,
}

export default spacing
