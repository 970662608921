import { configureStore } from "@reduxjs/toolkit"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"

import navColorReducer from "features/slices/navColorSlice"
import getStartedReducer from "features/slices/getStartedSlice"
import appStatusReducer from "features/slices/appStatusSlice"
import config from "config"

export const reducer = {
  navColor: navColorReducer,
  getStarted: getStartedReducer,
  appStatus: appStatusReducer,
}

export const store = configureStore({
  reducer,
  devTools: config.NODE_ENV === "development",
})

export type RootState = ReturnType<typeof store.getState>

type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
