import * as Sentry from "@sentry/nextjs";
import config from "config";

if (config.ENV === "production") {
  Sentry.init({
    environment: "production",
    dsn: config.sentry.dsn,
    tracesSampleRate: 1.0,
  });
}
