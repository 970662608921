import { createGlobalStyle, css } from "styled-components"

import { h1, h2, h3, h4, h5, h6, ol, p, ul } from "base_css/mixins"
import { breakpoint, rootFontSize } from "base_css/vars"

export const outlineStyle = css`
  outline: 2px solid ${({ theme }) => theme.color.primary};
`

const globalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: ${rootFontSize.small}px;

    ${breakpoint.medium} {
      font-size: ${rootFontSize.medium}px;
    }

    ${breakpoint.large} {
      font-size: ${rootFontSize.large}px;
    }
  }

  html,
  #root {
    height: 100%;
    width: 100%;
  }

  body {
    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.primary};
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-size: ${({ theme }) => theme.fontSize.body};
    font-style: normal;
    font-weight: normal;
    height: 100vh;
    letter-spacing: -0.01em;
    line-height: 1.4;
    overflow-x: hidden;
    padding: 0;
    text-align: center;
    width: 100%;

    ${breakpoint.mediumUp} {
      text-align: left;
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  *,
  input,
  button,
  a {
    &:focus { ${outlineStyle} }
    &:-moz-focusring { ${outlineStyle} }

    .no-focus-outline & {
      &:focus { outline: none; }
      &:-moz-focusring { outline: none; }
    }
  }

  input[type="radio"] {
    box-shadow: none;
  }

  h1 { ${h1} }
  h2 { ${h2} }
  h3 { ${h3} }
  h4 { ${h4} }
  h5 { ${h5} }
  h6 { ${h6} }
  p { ${p} }
  ol { ${ol} }
  ul { ${ul} }

  a {
    color: ${({ theme }) => theme.color.primary};
    cursor: pointer;
    text-decoration: none;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }

  img {
    max-width: 100%;
  }
`

export default globalStyle
