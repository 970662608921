import React, { useContext } from "react"
import Link from "next/link"
import styled from "styled-components"

import { CompanyContext } from "companyContext"
import { inlineLink } from "base_css/mixins"
import * as ga from "features/analytics/ga"
import ClickTracker from "features/analytics/ClickTracker"

interface ILink {
  analyticsID?: string
  children: React.ReactNode
  href: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  className?: string
  target?: string
}

const CustomLink = ({
  children,
  href,
  onClick,
  className,
  analyticsID,
}: ILink) => {
  const companyContext = useContext(CompanyContext)
  const isHash = href.startsWith("#")

  const addCompanyContextToParams = (path: string) => {
    let isFirst = true
    for (const [key, value] of Object.entries(companyContext)) {
      if (isFirst) {
        path = path + `?${key}=${value}`
        isFirst = false
      } else {
        path = `${path}&${key}=${value}`
      }
    }
    return path
  }

  if (!href.startsWith("/") && !isHash) {
    throw new Error("href must start with '/' or '#'")
  }

  let asHref,
    _href = href

  if (!isHash && companyContext.companyPath) {
    asHref = `/${companyContext.companyPath}${href}`
    _href = addCompanyContextToParams(_href)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (onClick) {
      onClick(event)
    }
    if (analyticsID) {
      ga.clickEvent(analyticsID)
    }
  }

  return (
    <Link href={_href} as={asHref}>
      <a className={className} onClick={handleClick}>
        {children}
      </a>
    </Link>
  )
}

const UnstyledInlineExternalLink = ({
  children,
  href,
  className,
  target,
  analyticsID,
  onClick,
}: ILink) => (
  <ClickTracker analyticsID={analyticsID}>
    <a className={className} href={href} target={target} onClick={onClick}>
      {children}
    </a>
  </ClickTracker>
)

const InlineCustomLink = styled(CustomLink)`
  ${inlineLink}
`

const InlineExternalLink = styled(UnstyledInlineExternalLink)`
  ${inlineLink}
  padding: ${({ theme }) => theme.spacing.xSmall};
`

export {
  CustomLink,
  InlineCustomLink,
  InlineExternalLink,
  UnstyledInlineExternalLink,
}
