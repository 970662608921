import React from "react"
import styled from "styled-components"

import { breakpoint } from "base_css/vars"
import { ExternalLinkButton } from "components/atoms/Button"
import GetStartedButton from "components/molecules/GetStartedButton"
import TrustpilotStar from "components/svg/TrustpilotStar"
import { SkeletonLine } from "components/atoms/Skeleton"
import { useTrustpilotRating } from "features/api/useRequest"

const STrustpilotReviews = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.medium};
  height: 100%;
  justify-content: flex-start;

  ${breakpoint.mediumDown} {
    align-items: center;
  }

  & > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.small};
  }
`

const STrustpilotHeader = styled.div`
  align-items: flex-end;
  display: inline-flex;
  justify-content: flex-start;

  & > *:not(:first-child) {
    margin-left: ${({ theme }) => theme.spacing.xSmall};
  }
`

const STrustpilotHeaderText = styled.div`
  display: block;
  font-size: ${({ theme }) => theme.fontSize.h4};
  height: auto;
  line-height: 100%;
`

const SRating = styled.div`
  font: ${({ theme }) =>
    `lighter ${theme.fontSize.h2} ${theme.fontFamily.header}`};
  text-align: left;

  ${breakpoint.mediumDown} {
    text-align: center;
  }
`

const STrustpilotReviewText = styled.div`
  text-align: left;

  ${breakpoint.mediumDown} {
    text-align: center;
    width: 75%;
  }
`

const SButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.small};
  justify-content: flex-start;

  ${breakpoint.mediumDown} {
    justify-content: space-around;
  }
`
const SExternalLinkButton = styled(ExternalLinkButton)`
  border: 2px solid ${({ theme }) => theme.color.white} !important;
`

interface ITrustpilotReviews {
  isDark: boolean
}

const TrustpilotReviews = ({ isDark }: ITrustpilotReviews) => {
  const { trustScore } = useTrustpilotRating()

  const getTrustScore = () => {
    if (!trustScore) {
      return <SkeletonLine width={"10em"} />
    } else {
      return `Rated ${trustScore} out of 5`
    }
  }

  return (
    <STrustpilotReviews>
      <STrustpilotHeader>
        <TrustpilotStar size={36} />
        <STrustpilotHeaderText>Trustpilot</STrustpilotHeaderText>
      </STrustpilotHeader>

      <SRating>{getTrustScore()}</SRating>

      <STrustpilotReviewText>
        Our clients love us. We&apos;re sure you will, too.
      </STrustpilotReviewText>
      <SButtonGroup>
        <SExternalLinkButton
          arrow
          small
          href="https://uk.trustpilot.com/review/novawm.com"
          analyticsID="Resources Trustpilot"
          variant={isDark ? "primary" : "secondary"}
        >
          More Reviews
        </SExternalLinkButton>
        <GetStartedButton
          analyticsID="Resources Trustpilot"
          variant={isDark ? "secondary" : "primary"}
        />
      </SButtonGroup>
    </STrustpilotReviews>
  )
}

export default TrustpilotReviews
