import styled, { css } from "styled-components"

import { rems } from "base_css/utils/fontSize"
import { breakpoint } from "base_css/vars"

interface IContainer {
  className?: string
  marginTop?: string
  mdMarginTop?: string
  lgMarginTop?: string
  marginBottom?: string
  mdMarginBottom?: string
  lgMarginBottom?: string
}

interface IGrid {
  className?: string
  columns?: number | string
  gap?: string
  columnGap?: string
  rowGap?: string
  height?: string
  minRowHeight?: string
  flow?: string
  rows?: string | number
  areas?: string[]
  justifyContent?: string
  alignContent?: string
}

interface ICell {
  className?: string
  width?: number
  mdWidth?: number
  lgWidth?: number
  height?: number
  mdHeight?: number
  lgHeight?: number
  left?: number | string
  mdLeft?: number | string
  lgLeft?: number | string
  top?: number | string
  mdTop?: number | string
  lgTop?: number | string
  middle?: boolean
  center?: boolean
  area?: string
  mdArea?: string
  lgArea?: string
}

const formatAreas = (areas: string[]) =>
  areas.map((area) => `"${area}"`).join(" ")
const frGetter = (value: string | number) =>
  typeof value === "number" ? `repeat(${value}, 1fr)` : value

const Container = styled.div<IContainer>`
  --horizontal-padding: ${({ theme }) => theme.spacing.medium};

  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : 0)};
  max-width: 1360px;
  padding-left: var(--horizontal-padding);
  padding-right: var(--horizontal-padding);
  width: 100%;

  ${breakpoint.mediumUp} {
    ${({ mdMarginBottom }) =>
      mdMarginBottom && `margin-bottom: ${mdMarginBottom}`};
    ${({ mdMarginTop }) => mdMarginTop && `margin-top: ${mdMarginTop}`};
  }

  ${breakpoint.large} {
    --horizontal-padding: ${({ theme }) => theme.spacing.large};

    ${({ lgMarginBottom }) =>
      lgMarginBottom && `margin-bottom: ${lgMarginBottom}`};
    ${({ lgMarginTop }) => lgMarginTop && `margin-top: ${lgMarginTop}`};
  }
`

const DesktopContainer = styled(Container)`
  ${breakpoint.mediumDown} {
    padding-left: 0;
    padding-right: 0;
  }
`

const Grid = styled.div<IGrid>`
  display: grid;
  grid-auto-flow: ${({ flow }) => (flow ? flow : `row`)};
  grid-auto-rows: ${({ minRowHeight, theme }) =>
    minRowHeight
      ? `minmax(${minRowHeight}, auto)`
      : `minmax(${theme.spacing.medium}, auto)`};
  grid-gap: ${rems(8)};
  grid-template-columns: ${({ columns }) =>
    columns ? frGetter(columns) : frGetter(12)};
  height: ${({ height }) => (height ? height : `auto`)};
  ${({ alignContent }) => alignContent && `align-content: ${alignContent}`};
  ${({ rows }) => rows && `grid-template-rows: ${frGetter(rows)}`};
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent}`};
  ${({ areas }) => areas && `grid-template-areas: ${formatAreas(areas)}`};
  row-gap: ${({ rowGap, gap, theme }) =>
    rowGap ? rowGap : !gap && theme.spacing.large};
  width: 100%;

  ${breakpoint.mediumUp} {
    ${({ gap }) =>
      gap &&
      css`
        grid-gap: ${gap};
      `};
    ${({ columnGap }) =>
      columnGap &&
      css`
        column-gap: ${columnGap};
      `};
  }
`

const Cell = styled.div<ICell>`
  grid-column-end: span ${({ width }) => (width ? width : 1)};
  grid-row-end: span ${({ height }) => (height ? height : 1)};
  min-width: 0;
  ${({ area }) => area && `grid-area: ${area}`};
  ${({ left }) => left && `grid-column-start: ${left}`};
  ${({ top }) => top && `grid-row-start: ${top}`};
  ${({ center }) => center && `text-align: center`};
  ${({ middle }) =>
    middle &&
    `
      display: inline-flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      justify-self: stretch;

      ${breakpoint.mediumUp} {
        align-items: flex-start;
      }
    `};

  ${breakpoint.mediumUp} {
    ${({ mdArea }) => mdArea && `grid-area: ${mdArea}`};
    ${({ mdWidth }) =>
      mdWidth &&
      `grid-column-end: span ${mdWidth}`}; /* stylelint-disable-next-line */
    ${({ mdLeft }) => mdLeft && `grid-column-start: ${mdLeft}`};
    ${({ mdHeight }) =>
      mdHeight &&
      `grid-row-end: span ${mdHeight}`}; /* stylelint-disable-next-line */
    ${({ mdTop }) => mdTop && `grid-row-start: ${mdTop}`};
  }

  ${breakpoint.large} {
    ${({ lgArea }) => lgArea && `grid-area: ${lgArea}`};
    ${({ lgWidth }) =>
      lgWidth &&
      `grid-column-end: span ${lgWidth}`}; /* stylelint-disable-next-line */
    ${({ lgLeft }) => lgLeft && `grid-column-start: ${lgLeft}`};
    ${({ lgHeight }) =>
      lgHeight &&
      `grid-row-end: span ${lgHeight}`}; /* stylelint-disable-next-line */
    ${({ lgTop }) => lgTop && `grid-row-start: ${lgTop}`};
  }
`

export { Cell, Container, DesktopContainer, Grid }
