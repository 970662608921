// tslint:disable:interface-name

import clientConfig from "config"

declare global {
  /* eslint-disable  @typescript-eslint/naming-convention */
  interface Window {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    Beacon: any
  }
}

class Helpscout {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private beacon: any

  constructor() {
    this.beacon = undefined
  }

  public init = () => {
    if (!this.beacon) {
      this.beacon = window.Beacon("init", clientConfig.helpscout.id)
    }
  }
}

const helpScout = new Helpscout()

export default helpScout
