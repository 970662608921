import React from "react"
import styled from "styled-components"

import { h2 } from "base_css/mixins"
import { LinkButton } from "components/atoms/Button"
import { Cell, Container, Grid } from "components/atoms/Grid"

const SResourcesSection = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.xLarge};
  padding-top: ${({ theme }) => theme.spacing.xLarge};
  text-align: center;
`

const SHeader = styled.h1`
  ${h2} /* stylelint-disable-next-line */
  color: ${({ theme }) => theme.color.accent};
  margin-bottom: ${({ theme }) => theme.spacing.mediumLarge};
  text-align: center;
`

const SGrid = styled(Grid)`
  grid-gap: ${({ theme }) => theme.spacing.medium};
  margin-bottom: ${({ theme }) => theme.spacing.mediumLarge};
  row-gap: ${({ theme }) => theme.spacing.medium};
`

interface IInlineCalculatorSection {
  children: JSX.Element
}

const InlineCalculatorSection = ({ children }: IInlineCalculatorSection) => (
  <SResourcesSection>
    <SHeader>Calculator</SHeader>
    <Container>
      <SGrid>
        <Cell width={12} mdWidth={10} mdLeft={2}>
          {children}
        </Cell>
      </SGrid>

      <LinkButton
        href="/calculators/"
        arrow
        small
        analyticsID="ViewMoreCalculatorsButton"
      >
        View more calculators
      </LinkButton>
    </Container>
  </SResourcesSection>
)

export default InlineCalculatorSection
